import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Our_Services_Data } from '../components/Data'
import cookies from "js-cookie";
import MultilineText from './MultilineText';

const OurServices = () => {
    const { t } = useTranslation();
    const [activeIndex, setActiveIndex] = useState(0);
    const scrollContainerRef = useRef(null);
    const currentLanguageCode = cookies.get('i18next');


    // Function to update the active dot based on scroll position
    const handleScrollEN = () => {
        const scrollPosition = scrollContainerRef.current.scrollLeft;
        const cardWidth = scrollContainerRef.current.firstChild.offsetWidth + 2; // Including gap (adjust gap size here)
        const index = Math.round(scrollPosition / cardWidth);
        setActiveIndex(index);
    };

    // Function to update the active dot based on scroll position
    const handleScrollAR = () => {
        const scrollPosition = scrollContainerRef.current.scrollLeft * -1;
        const cardWidth = scrollContainerRef.current.firstChild.offsetWidth + 2; // Including gap (adjust gap size here)
        const index = Math.round(scrollPosition / cardWidth);
        setActiveIndex(index);
    };

    // Scroll to the corresponding card when a dot is clicked
    const handleDotClick = (index) => {
        const cardWidth = scrollContainerRef.current.firstChild.offsetWidth + 2; // Including gap (adjust gap size here)
        scrollContainerRef.current.scrollTo({
            left: index * cardWidth,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        scrollContainer.addEventListener('scroll', currentLanguageCode === 'en' ? handleScrollEN : handleScrollAR);
        return () => scrollContainer.removeEventListener('scroll', currentLanguageCode === 'en' ? handleScrollEN : handleScrollAR);
    }, [currentLanguageCode]);

    return (
        <>
            {/* Mobile */}
            <div></div>
            {/* Desktop */}
            <div>
                <div className="grid grid-cols-2 items-center mx-[100px] mt-[100px]">
                    <h1 className="text-[48px]  text-[#333D46] font-semibold">{t('Our_Services')}</h1>
                    <div className='flex flex-col items-end '>
                        <div className='grid grid-cols-2'>
                            <h1 className="text-20px col-span-2 text-[#708FA0] font-medium"><MultilineText text={t('Our_Services_long_text')} /></h1>
                            <a href="https://wa.me/966581240150">
                                <p className='text-[16px] font-semibold text-[#496071] underline underline-offset-4 '>{t('Learn_more')}</p>
                            </a>

                        </div>

                    </div>

                </div>

                {/* Scrollable Cards Container */}
                <div
                    ref={scrollContainerRef}
                    className="overflow-x-scroll ps-[100px] pe-[326px] overflow-y-hidden pt-[80px] pb-[172px] gap-36 flex no-scrollbar"
                >
                    {/* This is cards Items */}
                    {Our_Services_Data.map((card, index) => (
                        <div key={index} className="relative flex-none">
                            <img className="h-[329px] w-[295px] object-cover rounded-[20px]" src={card.img} alt={`our_services_img_${index + 1}`} />

                            <div style={{ backgroundColor: card.divBG, borderColor: card.borderColor }} className={`absolute border-[1px] rounded-[10px] w-[295px] -bottom-32 -end-20 flex flex-col justify-start p-6`}>
                                <div style={{ backgroundColor: card.imgBG }} className="h-12 w-12 flex items-center justify-center bg-[#496071] rounded-[5px]">
                                    <img className="h-[30px] w-[30px]" src={card.iconImg} alt="" />
                                </div>
                                <h1 style={{ color: card.titleTextColor }} className="text-[16px] font-semibold mt-6 mb-[10px]">{currentLanguageCode === 'en' ? card.titleEN : card.titleAR}</h1>
                                <p style={{ color: card.contentTextColor }} className="text-[16px] font-normal ">{currentLanguageCode === 'en' ? card.descriptionEN : card.descriptionAR}</p>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Custom Dots Below the Cards */}
                <div className="flex justify-center mt-4 space-x-4 mb-[100px]">
                    {Our_Services_Data.map((_, index) => (
                        <div
                            key={index}
                            onClick={() => handleDotClick(index)}
                            className={`h-[16px] cursor-pointer transition-all duration-300 ${activeIndex === index ? 'w-[80px] bg-[#333D46]' : 'w-[16px] bg-[#E1E8EC]'
                                } rounded-full`}
                        ></div>
                    ))}
                </div>


            </div>
        </>
    );
};

export default OurServices;
