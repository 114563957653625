import MainLayout from "./pages/MainLayout";
import AboutUs from "./components/AboutUs";
import ContactUs from "./components/ContactUs";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import GlobalEffect from "./components/GlobalEffect";


i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ['en', 'ar'],
    fallbackLng: "en",
    detection:
    {
      order: ['cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'],
      caches: ['cookie']
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
    },
    react: { useSuspense: false },
  });

function App() {
  return (
    <>
      <BrowserRouter>
        <GlobalEffect />
        <Routes>
          <Route>
            <Route path="/" element={<MainLayout />} />
            <Route path="about" element={<AboutUs />} />
            <Route path="/contact" element={<ContactUs />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
