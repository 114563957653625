import React from 'react'
import Heart_Icon from '../images/WhyChooseUs/Heart_Icon.svg'
import Rectangle from '../images/WhyChooseUs/Rectangle.svg'
import Vector from '../images/WhyChooseUs/Vector.svg'
import Verified_Icon from '../images/WhyChooseUs/Verified_Icon.svg'
import { Why_Choose_Us_Data } from './Data'
import cookies from "js-cookie";
import { useTranslation } from 'react-i18next'
import MultilineText from './MultilineText'

const WhyChooseUs = () => {
    const { t } = useTranslation();
    const currentLanguageCode = cookies.get('i18next');

    return (
        <>
            {/* Moblle */}

            {/* Desktop */}
            <div className='flex justify-center items-center mx-[100px] mt-[120px] mb-[182px] gap-[84px] '>

                <div className='relative'>
                    <img src={Rectangle} alt='Rectangle' />

                    <div className={`absolute top-14 ${currentLanguageCode === 'en' ? '-left-20' : '-right-20'}  ps-6 pe-8 py-3 gap-5 flex rounded-[100px] bg-[#3C4C59]`}>
                        <img src={Verified_Icon} alt='quad' />
                        <div className='flex flex-col text-white text-[16px]'>
                            <h1 className='font-normal'>{t('Verified')}</h1>
                            <h1 className='font-semibold'>{t('Recommended')}</h1>
                        </div>
                    </div>

                    <div className={`absolute top-[188px] ${currentLanguageCode === 'en' ? '-right-20' : '-left-20'} py-6 px-9 flex flex-col rounded-[10px] bg-white`}>

                        <div className='relative'>
                            <div className='rounded-full bg-[#D9D9D9] h-[102px] w-[102px]'>
                                <img className='absolute top-0 right-0' src={Heart_Icon} alt='Quad heart Icon' />
                            </div>
                        </div>
                        <h1 className='text-[16px] font-semibold text-center mt-6'><MultilineText text={t('10_Years_of_Experience')} /></h1>
                    </div>


                </div>

                <div className='flex flex-col'>
                    <h1 className='text-[48px] font-semibold leading-none'><MultilineText text={t('Why_choose_quad')} /></h1>
                    <h1 className='text-[20px] text-[#708FA0] font-normal mt-6 mb-7'><MultilineText text={t('Our_Services_long_text')} /></h1>

                    <div className='flex flex-col gap-3'>
                        {Why_Choose_Us_Data.map((data => (
                            <>
                                <div className='flex justify-start gap-4'>
                                    <img src={Vector} alt='Vector Quad Dimentions' />
                                    <h1 className='text-[16px] font-semibold'>{currentLanguageCode === 'en' ? data.titleEN : data.titleAR}</h1>
                                </div>
                            </>
                        )))}

                    </div>
                    <div>
                        <button
                            onClick={() => window.open('https://wa.me/966581240150', '_blank')}
                            className='px-6 py-[10px] mt-[52px] text-white bg-mainGray text-[16px] rounded-[10px]'>{t('Get_a_Quote')}</button>
                    </div>

                </div>
            </div>
        </>
    )
}

export default WhyChooseUs