import React, { useState } from 'react'
import { Elevators_Data } from './Data';
import { useTranslation } from 'react-i18next';
import cookies from "js-cookie";


const Elevators = () => {
    const { t } = useTranslation();
    const [activeButton, setActiveButton] = useState(0); // State to track which button is active
    const currentLanguageCode = cookies.get('i18next');
    return (

        <>

            {/* Mobile */}
            <div></div>

            {/* Desktop */}
            <div className='bg-[#496071] flex flex-col w-full py-[80px] '>

                <div className='flex justify-center'>
                    <h1 className='text-[48px] font-semibold text-[#C6D4DB] mb-12 '>{t('Elevators')}</h1>
                </div>
                <div className="p-4">
                    {/* Button Group */}
                    <div className="flex justify-center mb-[100px] gap-7">
                        {Elevators_Data.map((_, index) => (
                            <button
                                key={index}
                                onClick={() => setActiveButton(index)}
                                className={`px-[30px] py-[10px] border text-[18px] font-medium rounded-full ${activeButton === index ? 'bg-[#C6D4DB] text-[#3A4650]' : 'text-[#C6D4DB] border-[1px] border-[#C6D4DB]'
                                    }`}
                            >
                                {currentLanguageCode === 'en' ? _.titleEN : _.titleAR}
                            </button>
                        ))}
                    </div>

                    {/* Display Image and Bullet Points */}
                    <div className="grid grid-cols-3 ps-60 items-center">
                        <div className='col-span-1 w-fit'>
                            <img
                                src={Elevators_Data[activeButton].image}
                                alt={`for Button ${activeButton + 1}`}
                                className="w-[373px] h-[496px] rounded-[20px] "
                            />

                        </div>
                        <div className='col-span-2 ps-[100px] flex justify-start'>


                            <ul className="list-disc text-white text-[32px] ">

                                {currentLanguageCode === 'en' ?

                                    Elevators_Data[activeButton].pointsEN.map((point, i) => (
                                        <li className='mb-14' key={i}>{point}</li>
                                    ))

                                    :

                                    Elevators_Data[activeButton].pointsAR.map((point, i) => (
                                        <li className='mb-14' key={i}>{point}</li>
                                    ))

                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Elevators