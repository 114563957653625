// Our Services Cards Icons
import Installations_Icon from '../images/OurServices/Installations_Icon.svg'
import Supply_Icon from '../images/OurServices/Supply_Icon.svg'
import Modernization_Icon from '../images/OurServices/Modernization_Icon.svg'
import maintenance_icon from '../images/OurServices/maintenance_icon.png'
import establishment_Icon from '../images/OurServices/establishment_Icon.png'
import consulting_Icon from '../images/OurServices/consulting_Icon.png'

// Our Services Cards Images
import maintenance_option_01 from '../images/OurServices/maintenance_option_01.jpg'
// import maintenance_option_02 from '../images/OurServices/maintenance_option_02.webp'
import elevator_tower_option_01 from '../images/OurServices/elevator_tower_option_01.jpg'
// import elevator_tower_option_02 from '../images/OurServices/elevator_tower_option_02.jpg'
// import elevator_tower_option_03 from '../images/OurServices/elevator_tower_option_03.jpg'

import testingImg from '../images/OurServices/our_services_img_01.png'
import supply_img from '../images/OurServices/supply_img.png'
import moderation_img from '../images/OurServices/moderation_img.png'

// Elevators Images
import gearbox_img from '../images/Elevators/gearbox_img.png'
import gearless_img_option_01 from '../images/Elevators/gearless_img_option_01.jpg'
// import gearless_img_option_02 from '../images/Elevators/gearless_img_option_02.png'

// import panorama_img_option_01 from '../images/Elevators/panorama_img_option_01.jpg'
import panorama_img_option_02 from '../images/Elevators/panorama_img_option_02.jpg'
// import panorama_img_option_03 from '../images/Elevators/panorama_img_option_03.jpg'
// import panorama_img_option_04 from '../images/Elevators/panorama_img_option_04.jpg'

import cars_img from '../images/Elevators/cars_img.jpg'

// import disables_img_option_01 from '../images/Elevators/disables_img_option_01.jpg'
import disables_img_option_02 from '../images/Elevators/disables_img_option_02.jpg'

// import food_img_option_01 from '../images/Elevators/food_img_option_01.jpg'
// import food_img_option_02 from '../images/Elevators/food_img_option_02.jpg'
import food_img_option_03 from '../images/Elevators/food_img_option_03.jpg'
// import food_img_option_04 from '../images/Elevators/food_img_option_04.jpg'
// import food_img_option_05 from '../images/Elevators/food_img_option_05.jpg'

// Safety Images
import Connecting_to_support_option_01 from '../images/Safety/Connecting_to_support_option_01.jpg'
// import Connecting_to_support_option_02 from '../images/Safety/Connecting_to_support_option_02.jpg'
import Electrical_systems from '../images/Safety/Electrical_systems.png'
// import Mechanical_systems_option_01 from '../images/Safety/Mechanical_systems_option_01.webp'
import Mechanical_systems_option_02 from '../images/Safety/Mechanical_systems_option_02.jpg'

// Our Designs Images
//////////// Cabins ////////////
import panorama_img_01 from '../images/OurDesigns/Cabins/panorama_img_01.jpg'
import classic_img from '../images/OurDesigns/Cabins/classic_img.jpg'
import decorative_img from '../images/OurDesigns/Cabins/decorative_img.jpg'

//////////// Doors ////////////
import automatic_center_door from '../images/OurDesigns/Doors/automatic_center_door.jpg'
import Telescopic_automatic_door from '../images/OurDesigns/Doors/Telescopic_automatic_door.jpg'
import Semi_automatic_door_01 from '../images/OurDesigns/Doors/Semi_automatic_door_01.jpg'

//////////// Doors ////////////
import touchless_button from '../images/OurDesigns/Buttons/touchless_button.jpg'
import touch_button from '../images/OurDesigns/Buttons/touch_button.jpg'

//////////// Walls ////////////
import cabin_walls_img_01 from '../images/OurDesigns/Walls/cabin_walls_img_01.png'
// import cabin_walls_img_02 from '../images/OurDesigns/Walls/cabin_walls_img_02.jpg'

//////////// Flooring ////////////
import Cabin_Flooring from '../images/OurDesigns/Flooring/Cabin_Flooring.jpg'

// Our Client Images
import Hospitals_img from '../images/OurClients/Hospitals_img.jpg'
import Commercial_Centers_img from '../images/OurClients/Commercial_Centers_img.jpg'
import Restaurants_img from '../images/OurClients/Restaurants_img.jpg'
import Hotels_img from '../images/OurClients/Hotels_img.jpg'
import Residential_Units_img from '../images/OurClients/Residential_Units_img.jpg'


const Our_Services_Data = [
    {
        id: "01",
        img: testingImg,
        iconImg: Installations_Icon,
        titleEN: 'Installations',
        titleAR: "تركيب",
        divBG: '#ffffff',
        imgBG: '#496071',
        titleTextColor: "#333D46",
        contentTextColor: "#708FA0",
        borderColor: "#C6D4DB",
        descriptionEN: 'Installation of fully imported elevators, Italian elevators with different cabin designs, escalators, safety systems ...',
        descriptionAR: "نقدم خدمات تركيب تلبي احتياجاتكم بدقة، بما في ذلك المصاعد المستوردة بالكامل والمصاعد الإيطالية بتصاميم كبائن مختلفة والسلالم الكهربائية وأنظمة السلامة وكاميرات المراقبة، مع ضمان أعلى معايير الجودة والأمان. ( عن طريق الكروت )."
    },
    {
        id: "02",
        img: supply_img,
        iconImg: Supply_Icon,
        titleEN: "Supply",
        titleAR: "توريد",
        divBG: '#496071',
        imgBG: '#C6D4DB',
        titleTextColor: "#E1E8EC",
        contentTextColor: "#ffffff",
        borderColor: "#496071",
        descriptionEN: 'Supplying parts for elevators, escalators, and safety systems of the highest quality available',
        descriptionAR: "توريد أجزاء المصاعد والسلالم الكهربائية وانظمة السلامة بأعلى جودة متوفرة"
    },
    {
        id: "03",
        img: moderation_img,
        iconImg: Modernization_Icon,
        titleEN: 'Modernization',
        titleAR: "تحديث",
        divBG: '#ffffff',
        imgBG: '#496071',
        titleTextColor: "#333D46",
        contentTextColor: "#708FA0",
        borderColor: "#C6D4DB",
        descriptionEN: 'We seek to provide the necessary updates to the cabin and operational parts to suit the changing tastes and needs of the customer.',
        descriptionAR: "نسعى  لتوفير التحديثات اللازمة للكابينة والأجزاء التشغيلية بما يتناسب مع أذواق واحتياجات العميل المتغيرة."
    },
    {
        id: "04",
        img: maintenance_option_01,
        iconImg: maintenance_icon,
        titleEN: "Maintenance",
        titleAR: "صيانة",
        divBG: '#496071',
        imgBG: '#C6D4DB',
        titleTextColor: "#E1E8EC",
        contentTextColor: "#ffffff",
        borderColor: "#496071",
        descriptionEN: 'Our technical team provides comprehensive maintenance to ensure the efficient and safe operation of your elevators and to increase their lifespan.',
        descriptionAR: "يقدم فريقنا الفني صيانة شاملة لضمان كفاءة وأمان تشغيل مصاعدكم وزيادة العمر الافتراضي."
    },
    {
        id: "05",
        img: elevator_tower_option_01,
        iconImg: establishment_Icon,
        titleEN: 'Establishing elevator towers',
        titleAR: "تأسيس أبراج للمصاعد",
        divBG: '#ffffff',
        imgBG: '#496071',
        titleTextColor: "#333D46",
        contentTextColor: "#708FA0",
        borderColor: "#C6D4DB",
        descriptionEN: 'We provide the service of establishing elevator towers with elaborate design and implementation according to the highest engineering standards.',
        descriptionAR: "نقدم خدمة تأسيس أبراج المصعد بتصميم وتنفيذ متقن وفق أعلى المعايير الهندسية."
    },
    {
        id: "06",
        img: testingImg,
        iconImg: consulting_Icon,
        titleEN: "Providing consultations",
        titleAR: "تقديم الإستشارات",
        divBG: '#496071',
        imgBG: '#C6D4DB',
        titleTextColor: "#E1E8EC",
        contentTextColor: "#ffffff",
        borderColor: "#496071",
        descriptionEN: 'We offer a free consultation service to provide you with the best solutions and recommendations based on your needs by specialists.',
        descriptionAR: "نقدم خدمة الاستشارات المجانية لتزويدكم بأفضل الحلول والتوصيات بناءً على احتياجاتكم من قبل مختصين."
    },
]

const Elevators_Data = [
    {
        titleEN: "Gearbox",
        titleAR: "جيربوكس",
        image: gearbox_img,
        pointsEN: ['High reliability', 'Ease of maintenance', 'Reasonable price'],
        pointsAR: ['اعتمادية عالية', 'سهولة صيانة', 'سعر مناسب'],
    },
    {
        titleEN: "Gearless",
        titleAR: "جيرليس",
        image: gearless_img_option_01, // Image URL
        pointsEN: ['Space saving', 'Low electricity consumption', 'Enhanced and smooth performance', 'Less noise'],
        pointsAR: ['توفير المساحة', 'استهلاك منخفض للكهرباء', 'أداء محسن وسلس', 'ضوضاء قليلة'],
    },
    {
        titleEN: "Panorama",
        titleAR: "بانوراما",
        image: panorama_img_option_02,
        pointsEN: ['Modern design improves the spatial experience.'],
        pointsAR: ['تصميم عصري يحسن من التجربة المكانية.'],
    },
    {
        titleEN: "Car Lifts",
        titleAR: "مصاعد سيارات",
        image: cars_img,
        pointsEN: [],
        pointsAR: [],
    },
    {
        titleEN: "Platform",
        titleAR: "ذوي الإحتياجات",
        image: disables_img_option_02,
        pointsEN: ['To facilitate their transportation and improve their experiences.'],
        pointsAR: ['لتسهيل تنقلاتهم وتحسين تجاربهم.'],
    },
    {
        titleEN: "Food",
        titleAR: "الطعام",
        image: food_img_option_03,
        pointsEN: ['A quick and easy solution for professional food transportation.'],
        pointsAR: ['حل سريع وسهل لنقل الطعام بإحترافية.'],
    }
];

const Safety_Data = [

    Mechanical_systems_option_02, // Image for Mechanical systems
    Electrical_systems, // Image for Electrical systems
    Connecting_to_support_option_01, // Image for Connecting to support

]

const Our_Designs_Data = [
    {
        id: 'Cabins',
        titleEN: 'Cabins',
        titleAR: 'الكبائن',
        descriptionEN: 'Spacious, modern cabins with durable<br />materials ensure a comfortable<br />and safe journey for<br />all passengers.',
        descriptionAR: 'مقصورات واسعة وحديثة بمواد<br />متينة توفر رحلة مريحة<br />وآمنة لجميع<br />الركاب.',
        images: [panorama_img_01, classic_img, decorative_img],
        titlesEN: ["Panorama", "Classic", "Decorative"], // Titles for images
        titlesAR: ["بانوراما", "كلاسيكية", "ديكورية"], // Titles for images
        link: 'https://drive.google.com/drive/folders/1HyItNaB-UGnaDDqEBOB9PAnl7ZmS1FuC',
    },
    {
        id: 'Doors',
        titleEN: 'Doors',
        titleAR: 'الأبواب',
        descriptionEN: 'High-quality, automatic doors for<br />smooth operation, ensuring security<br />and convenience at every<br />stop.',
        descriptionAR: 'أبواب أوتوماتيكية عالية الجودة<br />لضمان التشغيل السلس<br />والأمان والراحة<br />في كل طابق.',
        images: [automatic_center_door, Telescopic_automatic_door, Semi_automatic_door_01],
        titlesEN: ["Auto (center)", "Auto (telescopic)", "Semi Auto"], // Add titles here
        titlesAR: ["أوتوماتيكية (سنتر)", "أوتوماتيكية (تلسكوبي)", "نصف اوتوماتيك"], // Titles for images
        link: 'https://drive.google.com/drive/folders/1ry9YatqY-k2zX61ClMLvrkwwGsRyco7s',
    },
    {
        id: 'Buttons',
        titleEN: 'Buttons',
        titleAR: "الأزرار",
        descriptionEN: 'Responsive and illuminated buttons<br />provide easy navigation, enhancing<br />user experience in every<br />trip.',
        descriptionAR: 'أزرار مضيئة وسريعة الاستجابة<br />لسهولة التنقل، مما يعزز<br />تجربة المستخدم<br />في كل رحلة.',
        images: [touch_button, touchless_button],
        titlesEN: ["Touch", "Touchless"], // Add titles here
        titlesAR: ["لمس", "زر"], // Titles for images
        link: 'https://drive.google.com/drive/folders/10dKdITrBmohqKupSU6xOSmpfLl4xpRUE',
    },
    {
        id: 'Walls',
        titleEN: 'Walls',
        titleAR: "الجدران",
        descriptionEN: 'Elegant, durable wall panels<br />crafted to withstand wear while<br />adding style and sophistication<br />to the cabin.',
        descriptionAR: 'جدران أنيقة ومتينة مصممة<br />لتحمل الاستخدام المكثف<br />مع إضافة لمسة<br />من الأناقة للمقصورة.',
        images: [cabin_walls_img_01],
        titlesEN: ["Cabin Walls"], // Add titles here
        titlesAR: ["جداريات"], // Titles for images
        link: 'https://drive.google.com/drive/folders/1rcMVBFotbPQG8tVTihHpaHBntbIVPJt-',
    },
    {
        id: 'Flooring',
        titleEN: 'Flooring',
        titleAR: "الأرضيات",
        descriptionEN: "Anti-slip, stylish flooring ensures<br />safety and adds a modern<br />touch to the cabin's<br /> interior design.",
        descriptionAR: 'أرضيات عصرية ومضادة للانزلاق<br />لضمان الأمان ولمسة<br />عصرية في تصميم<br />المقصورة الداخلي.',
        images: [Cabin_Flooring],
        titlesEN: ["Cabin Flooring"], // Add titles here
        titlesAR: ["ارضيات الكبائن"], // Titles for images
        link: 'https://drive.google.com/drive/folders/1Q_L3ObbFY3UNcB_dqPs0sw88YFUtHyMU',
    },
];

const Our_Clients_Data = [
    {
        id: "01",
        img: Hospitals_img,
        titleEN: "Hospitals",
        titleAR: "المستشفيات",

    },
    {
        id: "02",
        img: Commercial_Centers_img,
        titleEN: "Commercial centers",
        titleAR: "المراكز التجارية",

    },
    {
        id: "03",
        img: Restaurants_img,
        titleEN: "Restaurants",
        titleAR: "المطاعم",

    },
    {
        id: "04",
        img: Hotels_img,
        titleEN: "Hotels",
        titleAR: "الفنادق",

    },
    {
        id: "05",
        img: Residential_Units_img,
        titleEN: "Residential units",
        titleAR: "الوحدات السكنية",
    }
]


const Why_Choose_Us_Data = [
    {
        titleEN: "Comprehensive Insurance Service Coverage",
        titleAR: "تغطية خدمة التأمين الشاملة",
    },
    {
        titleEN: "Competitive Insurance Fee Rates",
        titleAR: "أسعار رسوم التأمين تنافسية",
    },
    {
        titleEN: "Free inspection",
        titleAR: "فحص مجاني",
    },
    {
        titleEN: "Warranties up to 20 years",
        titleAR: "ضمانات تصل إلى 20 سنة",
    },
    {
        titleEN: "Inspection certificates approved by civil defense",
        titleAR: "شهادات فحص معتمدة من الدفاع المدني",
    },
    {
        titleEN: "Qualified and experienced technical team",
        titleAR: "فريق فني مؤهل وذو خبرة",
    },
    {
        titleEN: "High quality & competitive prices",
        titleAR: "جودة عالية وأسعار منافسة",
    },
    {
        titleEN: "Unique designs",
        titleAR: "تصاميم فريدة",
    }
]

export { Our_Services_Data, Elevators_Data, Our_Designs_Data, Safety_Data, Our_Clients_Data, Why_Choose_Us_Data }