import React, { useState } from 'react'
import emailjs from 'emailjs-com';
import { useTranslation } from 'react-i18next';

const LetsTalk = () => {
    const { t } = useTranslation();

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        subject: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        // Use emailjs to send the form data
        emailjs.send('service_5yyfaqj', 'template_jmewn0n', formData, '-Dqp5Ia1jl6qhAYVT')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                alert("Email sent successfully!");
            }, (err) => {
                console.log('FAILED...', err);
                alert("Email sending failed.");
            });
        setFormData({ firstName: '', lastName: '', subject: '', message: '' })
    };


    return (
        // Mobile

        // Desktop
        <div className='grid grid-cols-2'>
            <div>
                <iframe className='h-full w-full' src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3713.118462353725!2d39.934239999999996!3d21.463868!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjHCsDI3JzQ5LjkiTiAzOcKwNTYnMDMuMyJF!5e0!3m2!1sen!2ssa!4v1726137600355!5m2!1sen!2ssa" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className='page-1 flex flex-col px-[86px] pt-[51px]  bg-[#708FA0]'>
                <h1 className='text-[#333D46] text-[48px] font-semibold'>{t('Lets_talk_about_your_project')}</h1>
                <h1 className='text-[#C6D4DB] text-[16px] font-normal mt-4 mb-8'>{t('need_help')}</h1>

                <form className='text-[#333D46]' onSubmit={handleSubmit}>
                    <div className="grid grid-cols-2 mb-4 gap-5">
                        <div className='flex flex-col w-full'>
                            <label>{t('First_Name')}</label>
                            <input
                                type="text"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                placeholder={t('First_Name')}
                                className="mt-2 py-3 px-4 border text-[#C6D4DB] border-gray-300 bg-[#708FA0] rounded-[12px] focus:outline-none placeholder-[#515978]"
                            />
                        </div>
                        <div className='flex flex-col w-full'>
                            <label>{t('Last_Name')}</label>
                            <input
                                type="text"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                placeholder={t('Last_Name')}
                                className="mt-2 py-3 px-4 border text-[#C6D4DB] border-gray-300 bg-[#708FA0] rounded-[12px] focus:outline-none placeholder-[#515978]"
                            />
                        </div>
                    </div>

                    <div className="mb-4">
                        <label>{t('Subject')}</label>
                        <input
                            type="text"
                            name="subject"
                            value={formData.subject}
                            onChange={handleChange}
                            placeholder={t('Subject')}
                            className="w-full mt-2 py-3 px-4 border text-[#C6D4DB] border-gray-300 bg-[#708FA0] rounded-[12px] focus:outline-none placeholder-[#515978]"
                        />
                    </div>

                    <div className="mb-8">
                        <label>{t('Message')}</label>
                        <textarea
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            placeholder={t('Message')}
                            rows="5"
                            className="w-full mt-2 py-3 px-4 border text-[#C6D4DB] border-gray-300 bg-[#708FA0] rounded-[12px] focus:outline-none placeholder-[#515978]"
                        ></textarea>
                    </div>

                    <button
                        type="submit"
                        className="px-11 py-3 mb-11 bg-[#3C4C59] text-white rounded-[12px] hover:bg-[#4d5f6d] focus:outline-none"
                    >
                        {t('Send_a_Message')}
                    </button>
                </form>
            </div>
        </div>
    )
}

export default LetsTalk