import React, { useState } from 'react'
import logoSVG from '../images/logoSVG.svg'
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cookies from "js-cookie";
import i18next from 'i18next';

const Navbar = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const currentLanguageCode = cookies.get('i18next')
    const [language, setLanguage] = useState(currentLanguageCode); // State to handle re-render

    const changeLanguage = (lng) => {
        i18next.changeLanguage(lng);
        cookies.set('i18next', lng); // Ensure cookie updates
        setLanguage(lng); // Trigger a re-render by updating state
        window.location.reload();
    };
    return (
        <>
            {/* Mobile */}
            <div></div>
            {/* Desktop */}
            <div className='grid grid-cols-4 mx-24 pt-7 items-center'>

                {location.pathname === '/' && (

                    <div className='flex justify-start items-center col-span-3 gap-72'>
                        <div className='flex items-center gap-1'>
                            <img className='w-[53.89px] h-[66px]' src={logoSVG} alt='logo' />
                            <h1 className='text-white text-[24px] leading-6' dangerouslySetInnerHTML={{ __html: t('App_Name') }} />
                        </div>

                        <div className='flex justify-center w-full gap-10 text-[18px] text-white z-50'>
                            <a className='text-[#708FA0]' href='/'>{t('Home')}</a>
                            <a href='/about'>{t('navbar_About_Us')}</a>
                            <a href='/contact'>{t('Contact_Us')}</a>
                            {language === 'en' ?
                                <button className="border-[1px]  px-4 rounded-lg" onClick={() => changeLanguage('ar')}>
                                    <h1>ع</h1>
                                </button>
                                :
                                <button className="border-[1px] px-4 rounded-lg" onClick={() => changeLanguage('en')}>
                                    <h1>EN</h1>
                                </button>
                            }
                        </div>
                    </div>
                )}
                {location.pathname === '/about' && (

                    <div className='flex justify-start items-center col-span-3 gap-72'>
                        <div className='flex items-center gap-1'>
                            <img className='w-[53.89px] h-[66px]' src={logoSVG} alt='logo' />
                            <h1 className='text-[#3C4C59] text-[24px] leading-6' dangerouslySetInnerHTML={{ __html: t('App_Name') }} />
                        </div>

                        <div className='flex justify-center w-full gap-10 text-[18px] text-[#3C4C59]'>
                            <a href='/'>{t('Home')}</a>
                            <a className='text-[#708FA0]' href='/about'>{t('navbar_About_Us')}</a>
                            <a href='/contact'>{t('Contact_Us')}</a>
                            {language === 'en' ?
                                <button className="border-[1px]  px-4 rounded-lg" onClick={() => changeLanguage('ar')}>
                                    <h1>ع</h1>
                                </button>
                                :
                                <button className="border-[1px] px-4 rounded-lg" onClick={() => changeLanguage('en')}>
                                    <h1>EN</h1>
                                </button>
                            }
                        </div>
                    </div>
                )}
                {location.pathname === '/contact' && (

                    <div className='flex justify-start items-center col-span-3 gap-72'>
                        <div className='flex items-center gap-1'>
                            <img className='w-[53.89px] h-[66px]' src={logoSVG} alt='logo' />
                            <h1 className='text-[#3C4C59] text-[24px] leading-6' dangerouslySetInnerHTML={{ __html: t('App_Name') }} />
                        </div>

                        <div className='flex justify-center w-full gap-10 text-[18px] text-[#3C4C59]'>
                            <a href='/'>{t('Home')}</a>
                            <a href='/about'>{t('navbar_About_Us')}</a>
                            <a className='text-[#708FA0]' href='/contact'>{t('Contact_Us')}</a>
                            {language === 'en' ?
                                <button className="border-[1px]  px-4 rounded-lg" onClick={() => changeLanguage('ar')}>
                                    <h1>ع</h1>
                                </button>
                                :
                                <button className="border-[1px] px-4 rounded-lg" onClick={() => changeLanguage('en')}>
                                    <h1>EN</h1>
                                </button>
                            }
                        </div>
                    </div>
                )}

                <div className='flex justify-end gap-20'>
                    <button
                        onClick={() => window.open('https://wa.me/966581240150', '_blank')}
                        className='px-6 py-3 text-white bg-mainGray text-[16px] rounded-[10px]'>{t('Get_a_Quote')}</button>
                </div>
            </div>
        </>
    )
}

export default Navbar