import React from 'react'
import dribbble from '../images/Footer/dribbble.png'
import instagram from '../images/Footer/instagram.png'
import facebook from '../images/Footer/facebook.png'
import { useTranslation } from 'react-i18next'
import MultilineText from './MultilineText'
import logoSVG from '../images/logoSVG.svg'
import company_profile from '../images/Footer/company_profile.png'
import vat_logo from '../images/Footer/vat_logo.png'
import cookies from "js-cookie";

const Footer = () => {
    const { t } = useTranslation();
    const currentLanguageCode = cookies.get('i18next');
    const emailSubjectEN = 'Inquiry About Elevator Services';
    const emailSubjectAR = 'استفسار حول خدمات المصاعد';

    return (
        <div>
            {/* Up Footer */}
            <div className='flex justify-between px-20 pb-[67px] pt-[44px] bg-[#3A4650]'>
                <div className='flex flex-col'>
                    <div className='flex justify-start mb-2 items-center'>
                        <img className='w-[53.89px] h-[66px]' src={logoSVG} alt='logo' />
                        <h1 className='text-[24px] leading-7 text-[#F3F7F8] font-semibold' dangerouslySetInnerHTML={{ __html: t('App_Name') }} />
                    </div>
                    <h1 className='text-[14px] text-[#C6D4DB]'>
                        <MultilineText text={t('Footer_Long_Text')} />
                    </h1>
                </div>

                <div className='flex justify-start gap-[52px]'>


                    <div className='flex flex-col text-[14px] text-[#C6D4DB]'>
                        <h1 className='text-[#F3F7F8] text-[16px] font-bold mb-[26px]'>{t('Attachments')}</h1>
                        <button
                            onClick={() => window.open('https://drive.google.com/file/d/1FnmJhIh6Et3mPxkKQY-B9rFmmdkePKJR/view?usp=sharing', '_blank')}
                        >
                            <div className='flex justify-start mb-[20px]'>
                                <img className='h-5 w-5 me-2' src={company_profile} alt='company profile' />
                                <h1>{t('Our_Profile')}</h1>
                            </div>
                        </button>
                        <button
                            onClick={() => window.open('https://drive.google.com/file/d/1T3HJm13unuxNFNUR8g6pgTUdxiz7sL04/view?usp=sharing', '_blank')}
                        >
                            <img className='w-[60px] ' src={vat_logo} alt='vat logo'/>
                        </button>
                    </div>

                    <div className='flex flex-col text-[14px] text-[#C6D4DB]'>
                        <h1 className='text-[#F3F7F8] text-[16px] font-bold mb-[26px]'>{t('Company')}</h1>
                        <a className='mb-[20px]' href='/about'>
                            <h1>{t('About_Us')}</h1>
                        </a>
                        <a className='mb-[20px]' href='/contact'>
                            <h1>{t('Contact')}</h1>
                        </a>
                    </div>

                    <div className='flex flex-col text-[14px] text-[#C6D4DB]'>
                        <h1 className='text-[#F3F7F8] text-[16px] font-bold mb-[26px]'>{t('Countact_Us')}</h1>
                        <a className='mb-[20px]' href='/'>
                            <h1>{t('Website')}</h1>
                        </a>
                        <a className='mb-[20px]' href={`mailto:info@quad.com?subject=${currentLanguageCode === 'en' ? emailSubjectEN : emailSubjectAR}`}>
                            <h1>{t('Email')}</h1>
                        </a>
                        <a className='mb-[20px]' href='https://wa.me/966581240150'>
                            <h1>{t('Whatsapp')}</h1>
                        </a>
                    </div>

                    <div className='flex flex-col text-[14px] text-[#C6D4DB]'>
                        <h1 className='text-[#F3F7F8] text-[16px] font-bold mb-[26px]'>{t('Support')}</h1>
                        <a className='mb-[20px]' href='https://wa.me/966581240150'>
                            <h1>{t('Get_a_Quote')}</h1>
                        </a>
                        <a className='mb-[20px]' href='https://wa.me/966581240150'>
                            <h1>{t('Booking')}</h1>
                        </a>
                    </div>

                    <div className='flex flex-col text-[14px] text-[#C6D4DB]'>
                        <h1 className='text-[#F3F7F8] text-[16px] font-bold mb-[26px]'>{t('Office')}</h1>
                        <a className='mb-[20px]' href='/about'>
                            <h1><MultilineText text={t('location_typing')} /></h1>
                        </a>
                        <div>

                            <a className='mb-[20px]' href='tel:+966581240150'>
                                <h1>058 124 0150</h1>
                            </a>
                            <a className='mb-[20px]' href='tel:+966533566889'>
                                <h1>053 356 6889</h1>
                            </a>
                            <a className='mb-[20px]' href='tel:+966502093053'>
                                <h1>050 209 3053</h1>
                            </a>
                        </div>
                    </div>

                </div>
            </div>

            {/* Down Footer */}
            <div className='flex justify-between bg-[#708FA0] px-20 py-6'>
                {/* <div className='flex '>
                    <button>
                        <img src={company_profile} />
                    </button>
                    <button>
                        <img src={vat_logo} />
                    </button>
                </div> */}
                <div>
                    <h1 className='text-[16px] text-white'>{t('Copywrite')}</h1>
                </div>
                <div className='flex justify-start gap-5'>
                    <a href='https://google.com'> <img src={dribbble} alt='dribbble' /></a>
                    <a href='https://google.com'> <img src={instagram} alt='instagram' /></a>
                    <a href='https://google.com'> <img src={facebook} alt='facebook' /></a>
                </div>
            </div>
        </div>
    )
}

export default Footer