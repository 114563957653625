import React from 'react'
import heroImg from '../images/Hero/heroImg.png'
import heroLinyer from '../images/Hero/heroLinyer.png'
import Navbar from './Navbar'
import { useTranslation } from 'react-i18next'
import MultilineText from './MultilineText'
import cookies from "js-cookie";

const Hero = () => {
    const { t } = useTranslation();
    const currentLanguageCode = cookies.get('i18next');
    const scrollToSection = (id) => {
        document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <>

            {/* Mobile */}
            {/* Desktop */}

            <div className="relative h-[896px] bg-cover bg-center bg-no-repeat z-20" style={{ backgroundImage: `url(${heroImg})` }}>
                <div className={`absolute ${currentLanguageCode === 'en' ? '' : 'scale-x-[-1]'} inset-0 bg-cover bg-center bg-no-repeat -z-10`} style={{ backgroundImage: `url(${heroLinyer})` }} />

                <Navbar />

                <div className='ms-[100px]'>

                    <h1 className='text-white text-[64px] font-semibold textce leading-[1.15] mt-28 '>
                        <MultilineText text={t('Hero_Title')} />
                    </h1>

                    <h1 className='text-white text-[16px] font-normal leading-[24px] my-8'><MultilineText text={t('Hero_Long_Text')} /></h1>

                    <div className='flex gap-5 z-10'>
                        <button
                            onClick={() => scrollToSection('ourServices')}
                            className='py-4 px-5 bg-[#333D46] text-[#9FB5C1] text-[16px] font-medium rounded-[10px]'>{t('Our_Services')}</button>
                        <button
                            onClick={() => window.open('https://wa.me/966581240150', '_blank')}
                            className='py-4 px-5 bg-[#9FB5C1] text-white text-[16px] font-medium rounded-[10px]'>{t('Get_a_Quote')}</button>
                    </div>

                </div>
            </div>

        </>
    )
}

export default Hero